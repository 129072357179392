import * as React from "react";
import ProfileIcon from "./ProfileIcon";
import { Icon } from "@fluentui/react/lib/Icon";
import { useBoolean, useId } from "@fluentui/react-hooks";
import {
  adminModeSpan,
  cdsModeToggle,
  emptySearchBar,
  headerNavBase,
  icon,
  logoBase,
  logoIcon,
  logoText,
  navBarDiv,
  notificationBadge,
  notificationBadgeContainer,
  regionMenuBar,
  wikiCallout,
  wikiText,
} from "./Styles";
import { useDispatch, useSelector } from "react-redux";
import {
  ADMIN_MODE,
  CUSTOMER_MODE,
  selectAppMode,
  set_userMode,
} from "store/modeSlice";
import { useNavigate } from "react-router-dom";
import { Callout, Dropdown, TooltipHost } from "@fluentui/react";
import NavMenuItem from "./NavMenuItem";

const NavHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentMode = useSelector((state) => state.mode.currentUserMode);
  const notificationList = useSelector((state) => state.notification.list);
  const appModes = useSelector((state) => state.mode.appModes);
  const userModes = useSelector((state) => state.mode.userModes);
  const selectedMode = useSelector((state) => state.mode.currentAppMode);
  const unread = notificationList.filter((notification) => {
    return !notification.isRead && !notification.isDeleted;
  }).length;
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const buttonId = useId("callout-button");

  const switchToAdminMode = React.useCallback(
    (path = "/") => {
      dispatch(set_userMode(ADMIN_MODE));
      localStorage.setItem("mode", ADMIN_MODE);
      navigate(path);
    },
    [dispatch, navigate]
  );

  const switchToCustomerMode = React.useCallback(() => {
    dispatch(set_userMode(CUSTOMER_MODE));
    localStorage.setItem("mode", CUSTOMER_MODE);
    navigate(window.location.pathname);
  }, [dispatch, navigate]);

  const adminToggleIcon = () => {
    if (userModes.length < 2) {
      return null;
    }

    if (userModes.includes(ADMIN_MODE) && currentMode === CUSTOMER_MODE) {
      return (
        <NavMenuItem
          onClick={() => switchToAdminMode(window.location.pathname)}
          tooltipContent="Switch to Admin Mode"
          ariaLabel="Toggle Admin Mode"
        >
          <Icon iconName="CRMServices" className={icon} />
        </NavMenuItem>
      );
    } else if (
      userModes.includes(CUSTOMER_MODE) &&
      currentMode === ADMIN_MODE
    ) {
      return (
        <NavMenuItem
          onClick={() => switchToCustomerMode()}
          tooltipContent="Switch to Customer Mode"
          ariaLabel="Toggle Customer Mode"
        >
          <Icon iconName="Contact" className={icon} />
        </NavMenuItem>
      );
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    if (currentMode === ADMIN_MODE) return;

    const modeFromStorage = localStorage.getItem("mode");
    if (modeFromStorage === ADMIN_MODE.toString()) {
      switchToAdminMode(window.location.pathname);
    }
  }, [switchToAdminMode, currentMode]);

  return (
    <div className={headerNavBase}>
      <div className={navBarDiv}>
        <a
          aria-label="CTS Portal home page"
          className={logoBase}
          href="/"
          tabIndex={0}
        >
          <Icon iconName="AzureLogo" className={logoIcon} />
          <span className={logoText}>CTS Portal</span>
        </a>
        {currentMode === CUSTOMER_MODE ? (
          <div className={emptySearchBar} />
        ) : (
          <div className={adminModeSpan}>ADMIN MODE</div>
        )}
        <div className={regionMenuBar}>
          {currentMode === ADMIN_MODE && (
            <Dropdown
              selectedKey={selectedMode}
              options={appModes.map((mode) => ({
                key: mode,
                text: mode.toUpperCase(),
              }))}
              onChange={(event, option, index) => {
                if (selectedMode !== option.key) {
                  dispatch(selectAppMode(option.key));
                }
              }}
              className={cdsModeToggle}
            />
          )}
          {adminToggleIcon()}
          <NavMenuItem
            onClick={() => navigate("/notifications")}
            tooltipContent="Notifications"
          >
            <Icon iconName="Ringer" className={icon} />
            {unread > 0 && (
              <div className={notificationBadgeContainer}>
                <span className={notificationBadge}>{unread}</span>
              </div>
            )}
          </NavMenuItem>
          <NavMenuItem
            onClick={() =>
              window.open("https://aka.ms/ctsportalwiki", "_blank")
            }
            tooltipContent="Help"
            ariaLabel="Go to CTS Portal Documentation Wiki"
          >
            <div>
              <Icon iconName="Help" className={icon} />
            </div>
          </NavMenuItem>
          <TooltipHost content="Manage Account">
            <ProfileIcon />
          </TooltipHost>
        </div>
      </div>
    </div>
  );
};

export default NavHeader;
